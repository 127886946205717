import { ReactComponent as Graduation } from '@/images/graduation.svg';
import { ReactComponent as Zip } from '@/images/zip.svg';
import { ReactComponent as Briefcase } from '@/images/briefcase.svg';
import { ReactComponent as File } from '@/images/file+XP.svg';
import { ReactComponent as Bookmark } from '@/images/book-bookmark.svg';
import { ReactComponent as StopWatch } from '@/images/stop-watch.svg';

export default [
  {
    label: 'fields.missions',
    value: 'missions',
    id: 'missions',
    icon: Zip,
    'data-testid': 'missions',
    choiceVisibility: true,
  },
  {
    label: 'fields.missions_or_fulltime_contract',
    value: 'missions_or_fulltime_contract',
    id: 'missions_or_fulltime_contract',
    icon: File,
    'data-testid': 'missions_or_fulltime_contract',
    choiceVisibility: true,
  },
  {
    label: 'fields.fulltime_contract',
    value: 'fulltime_contract',
    id: 'fulltime_contract',
    icon: Briefcase,
    'data-testid': 'fulltime_contract',
    visible: true,
    choiceVisibility: true,
  },
  {
    label: 'fields.fixed_term_contract',
    value: 'fixed_term_contract',
    id: 'fixed_term_contract',
    icon: StopWatch,
    'data-testid': 'fixed_term_contract',
    choiceVisibility: true,
  },
  {
    label: 'fields.apprenticeship',
    value: 'apprenticeship',
    id: 'apprenticeship',
    icon: Bookmark,
    'data-testid': 'apprenticeship',
    choiceVisibility: true,
  },
  {
    label: 'fields.internship',
    value: 'internship',
    id: 'internship',
    icon: Graduation,
    'data-testid': 'internship',
    choiceVisibility: true,
  },
];
