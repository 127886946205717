import React, { useContext } from 'react';
import NavCounter from '@/components/NavCounter';
import UserContext from '@/permissions/contexts/UserContext';
import UserModel from '@/models/User';

function InvoiceCount() {
  const user = useContext(UserContext);
  const currentUser = new UserModel(user);

  return (
    <NavCounter
      name={
        currentUser.isClient()
          ? 'delayed_invoices_count'
          : 'proforma_invoices_count'
      }
    />
  );
}

export default InvoiceCount;
