import {
  Automative,
  Aeronautics,
  Space,
  Railway,
  Telecoms,
  Energy,
  SI,
  Digital,
  Infrastructure,
  BioEngineering,
  Insurance,
  AuditConsulting,
  FranchiseDistributionCommerce,
  ConstructionRealEstate,
  Education,
  FintechAccountingTech,
  HotelRestaurant,
  Industry,
  ComputingTechnology,
  LegalJustice,
  LeisureCultureArts,
  Multimedia,
  FashionLuxuryBeauty,
  MarketingCommunication,
  HumanResourcesRecruitment,
  Environment,
  HealthWithIcon,
  Associative,
  FinancesServicesWithIcon,
  TransportLogistics,
  HospitalityTourism,
  Other,
} from './sectors_default';

export default [
  BioEngineering,
  Automative,
  Aeronautics,
  Space,
  Energy,
  Digital,
  Railway,
  Telecoms,
  SI,
  Infrastructure,
  Insurance,
  AuditConsulting,
  FranchiseDistributionCommerce,
  ConstructionRealEstate,
  Education,
  FintechAccountingTech,
  HotelRestaurant,
  Industry,
  ComputingTechnology,
  LegalJustice,
  LeisureCultureArts,
  Multimedia,
  FashionLuxuryBeauty,
  MarketingCommunication,
  HumanResourcesRecruitment,
  Environment,
  HealthWithIcon,
  Associative,
  FinancesServicesWithIcon,
  TransportLogistics,
  HospitalityTourism,
  Other,
];
