import { isExpert } from '@/selectors/user';
import JobSection from './JobSection';
import ContractTypeSection from './ContractTypeSection';
import CompanySection from './CompanySection';
import ExperienceSection from './ExperienceSection';
import RemunerationSection from './RemunerationSection';
import ModalitiesSection from './ModalitiesSection';
import WorkRateSection from './WorkRateSection';
import AvailabilitySection from './AvailabilitySection';
import SearchStatusSection from './SearchStatusSection';
import SizeSection from './SizeSection';
import SocialNetworksSection from './SocialNetworksSection';

export default ({ leader }) => {
  const isLeaderExpert = isExpert(leader);
  return [
    isLeaderExpert && { id: 'searchStatus', component: SearchStatusSection },
    isLeaderExpert && { id: 'availability', component: AvailabilitySection },
    isLeaderExpert && { id: 'contractType', component: ContractTypeSection },
    { id: 'company', component: CompanySection },
    { id: 'job', component: JobSection },
    isLeaderExpert && { id: 'experience', component: ExperienceSection },
    isLeaderExpert && { id: 'remuneration', component: RemunerationSection },
    { id: 'modalities', component: ModalitiesSection },
    isLeaderExpert && { id: 'workRate', component: WorkRateSection },
    !isLeaderExpert && { id: 'size', component: SizeSection },
    !isLeaderExpert && {
      id: 'social_networks',
      component: SocialNetworksSection,
    },
  ].filter((s) => s);
};
