/* eslint-disable import/export */
import { get } from 'lodash';
import { defaultFilters as getCommonFilters } from './common';
import config from '@/_config';

export * from './common';

export function defaultFilters(user) {
  const commonFilters = getCommonFilters();

  return {
    ...commonFilters,
    experts: get(user, 'administrator')
      ? `${commonFilters.experts}`
      : `${
          commonFilters.experts
        } AND is_registered: true AND leader_current_score >= ${
          config.company.maxRelevanceScore
        }`,
  };
}
