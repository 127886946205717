/*
/!\ PLEASE DO NOT USE THE DEFAULT EXPORT OF THIS FILE TO GET COMPANY TYPES
    INSTEAD USE HELPER getCompanyTypes() from @/helper/company.js
*/

import { FREELANCE } from '@/constants/companyKind';

export const accountingAndAuditingFirm = {
  label: 'fields.companyKinds.accounting_and_auditing_firm',
  value: 'accounting_and_auditing_firm',
  id: 'accounting_and_auditing_firm',
  selectable: true,
  solo: false,
};

export const consulting = {
  label: 'fields.companyKinds.consulting',
  value: 'consulting',
  id: 'consulting',
  selectable: true,
  solo: false,
};

export const freelance = {
  label: 'fields.companyKinds.freelance',
  value: FREELANCE,
  id: FREELANCE,
  selectable: true,
  solo: true,
};

export const majorCompany = {
  label: 'fields.companyKinds.major_company',
  value: 'major_company',
  id: 'major_company',
  selectable: true,
  solo: false,
};

export const midSizedCompany = {
  label: 'fields.companyKinds.mid_sized_company',
  value: 'mid_sized_company',
  id: 'mid_sized_company',
  selectable: true,
  solo: false,
};

export const recruitmentCandidate = {
  label: 'fields.companyKinds.recruitment_candidate',
  value: 'recruitment_candidate',
  id: 'recruitment_candidate',
  selectable: true,
  solo: true,
};

export const startup = {
  label: 'fields.companyKinds.startup',
  value: 'startup',
  id: 'startup',
  selectable: true,
  solo: false,
};

export const smes = {
  label: 'fields.companyKinds.smes',
  value: 'smes',
  id: 'smes',
  selectable: true,
  solo: false,
};

export const timeSharingCfo = {
  label: 'fields.companyKinds.time_sharing_cfo',
  value: 'time_sharing_cfo',
  id: 'time_sharing_cfo',
  selectable: true,
  solo: false,
};

export const unknown = {
  label: 'fields.companyKinds.unknown',
  value: 'unknown',
  id: 'unknown',
  selectable: false,
  solo: false,
};

export default [
  recruitmentCandidate,
  freelance,
  startup,
  midSizedCompany,
  timeSharingCfo,
  accountingAndAuditingFirm,
  consulting,
  smes,
  majorCompany,
  unknown,
];
