import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import ValidateProformaModal from './ValidateProformaModal';
import { validateProformaInvoice } from '@/api/Invoice/mutations';
import { showFlash } from '@/redux/modules/flash';

export default compose(
  graphql(validateProformaInvoice, { name: 'validateProformaInvoice' }),
  connect(
    null,
    { showFlash }
  )
)(ValidateProformaModal);
